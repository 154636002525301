/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */

import {graphql} from 'gatsby'
import {jsx, Styled} from 'theme-ui'
import {
  Layout,
  HeroImg,
  PhotoGallery,
  ContentImg,
  Promotion,
  Contact,
  SEO,
  SliceZone,
} from '../components'
import {ListItem} from '../components/Listing'
import Navigation from '../components/navigation'
import locales from '../../config/i18n'
import {langPrefix} from '../utils/lang-prefix'

import {Container, PostsWrapper} from '../elements'

const Homepage = ({
  data: {homepage, allEvents, allBlogs, prismicForm, i18nPathname},
}) => {
  const {lang} = homepage

  return (
    <Layout>
      <Navigation lang={lang} i18nPathname={i18nPathname.nodes} />
      <SEO data={homepage.data} />
      <HeroImg
        title={homepage.data.header_title}
        subtitle={homepage.data.header_subtitle}
        bg={homepage.data.header_bg.localFile.childImageSharp.fluid}
      />
      <Container>
        <ContentImg
          title={homepage.data.section_1_title}
          content={homepage.data.section_1_content}
          img={homepage.data.image.localFile.childImageSharp.fluid}
          btn1={homepage.data.btn_sec_1}
          btn1Link={homepage.data.primaryBtnLink}
          primaryAnchor={homepage.data.primaryAnchorLink}
          btn2={homepage.data.btn2_sec_1}
          secondaryAnchor={homepage.data.secondaryAnchorLink}
          btn2Link={homepage.data.secondaryBtnLink}
        />
      </Container>

      <Container>
        <Styled.h1
          sx={{
            margin: '0 auto',
            textAlign: 'center',
            mb: '3rem',
          }}
        >
          {homepage.data.sec2_title}
        </Styled.h1>
        <PhotoGallery photos={homepage.data.sec2_gallery} />
      </Container>

      <Container sx={{maxWidth: '1200px'}}>
        <Promotion
          title={homepage.data.sec3_content}
          btn1={homepage.data.sec3_btn}
          btn2={homepage.data.sec_btn2}
        />
      </Container>

      <Container>
        <ContentImg
          title={homepage.data.sec4_title}
          content={homepage.data.sec4_content}
          img={homepage.data.sec4_image.localFile.childImageSharp.fluid}
          btn1={homepage.data.sec4_btn}
          btn1Link={homepage.data.buchBtnLink}
        />
      </Container>

      {/* If there's events data */}
      {allEvents.nodes.length > 0 && (
        <Container>
          <Styled.h1
            sx={{
              mb: [0, '', '1rem'],
            }}
          >
            Kommende Veranstaltungen
          </Styled.h1>
          <PostsWrapper>
            {allEvents.nodes.map((item) => (
              <ListItem
                img={item.data.image.localFile.childImageSharp.fluid}
                capTitle={item.data.cb_title}
                date={item.data.event_date}
                path={`/veranstaltungen/${item.uid}`}
                isFuture={item.isFuture}
              />
            ))}
          </PostsWrapper>
        </Container>
      )}

      <Contact data={prismicForm} lang={homepage.lang} />

      <Container>
        <Styled.h1
          sx={{
            pb: 4,
            textAlign: 'center',
          }}
        >
          {homepage.data.map_title}
        </Styled.h1>
        <div
          sx={{
            overflow: 'hidden',
            paddingBottom: '56.25%',
            position: 'relative',
            height: 0,
          }}
        >
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2707.1565891971304!2d8.820480716077935!3d47.27219341901109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ab9e6a48db42b%3A0xa5cd2f8050f79d62!2sSennweidstrasse%201A%2C%208608%20Bubikon!5e0!3m2!1sen!2sch!4v1602169530645!5m2!1sen!2sch"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            sx={{
              left: 0,
              top: 0,
              height: '100%',
              width: '100%',
              position: 'absolute',
              border: 0,
            }}
          />
        </div>
      </Container>

      {/* If there's blog data */}
      {allBlogs.nodes.length > 0 && (
        <Container>
          <Styled.h1>{locales[lang].blogEntry}</Styled.h1>
          <PostsWrapper>
            {allBlogs.nodes.map((item) => (
              <ListItem
                img={item.data.post_image.localFile.childImageSharp.fluid}
                capTitle={item.data.cb_title}
                date={item.last_publication_date}
                path={langPrefix(item.uid, lang, 'blog')}
              />
            ))}
          </PostsWrapper>
        </Container>
      )}
      <SliceZone allSlices={homepage.data.body} />
    </Layout>
  )
}
export const query = graphql`
  query Homepage($lang: String) {
    i18nPathname: allPrismicHomepage {
      nodes {
        lang
      }
    }
    homepage: prismicHomepage(lang: {eq: $lang}) {
      lang
      ...ContactFormInfo
      data {
        header_title
        header_subtitle
        header_bg {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        section_1_title
        section_1_content
        btn_sec_1
        primaryAnchorLink: primary_anchor_link
        primaryBtnLink: primary_button_link {
          id
          url
          uid
          slug
          type
          linkType: link_type
        }
        secondaryAnchorLink: secondary_anchor_link
        btn2_sec_1
        secondaryBtnLink: secondary_button_link {
          id
          url
          uid
          slug
          type
          linkType: link_type
        }
        image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        sec2_title
        sec2_gallery {
          sec2_gallery_image {
            localFile {
              childImageSharp {
                fluid(quality: 50, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
                original {
                  height
                  src
                  width
                }
              }
            }
          }
        }
        sec3_content
        sec3_btn
        sec_btn2
        buchBtnLink: primary_button_link2 {
          id
          url
          uid
          slug
          type
          linkType: link_type
        }

        sec4_title
        sec4_content
        sec4_btn
        sec4_image {
          localFile {
            childImageSharp {
              fluid(quality: 40, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        map_title

        seo_title
        seo_description
        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }

    prismicForm(lang: {eq: $lang}) {
      lang
      ...FormInformation
    }

    allEvents: allPrismicEvents(
      sort: {fields: data___event_date, order: ASC}
      filter: {isFuture: {eq: true}, lang: {eq: $lang}}
      limit: 2
    ) {
      nodes {
        ...EventPost
      }
    }

    allBlogs: allPrismicBlogeintrag(
      filter: {lang: {eq: $lang}}
      sort: {fields: last_publication_date, order: ASC}
      limit: 2
    ) {
      nodes {
        ...BlogPost
      }
    }
  }
`

export default Homepage
